import React from "react";
import GlobalLayout from "../layouts/globalLayout";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import styles from './404.module.css';

export default function Page404() {
  return (
    <GlobalLayout bodyBackgroundColor="rgb(212, 212, 212)">
      <div className={styles.container}>
        <div className={styles.text__container}>
          <SectionHeader title="Page Not Found" className={styles.header} />
          <div className={styles.paragraph}>
              Sorry, we can not find the page specified.
          </div>
        </div>
      </div>
    </GlobalLayout>
  );
}